@import "src/styles/vars";

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  padding: 20px 5% 20px 10vw;
}

.logo {
  cursor: pointer;
}

li, a {
  font-weight: 500;
  font-size: 16px;
  color: $main-color;
  text-decoration: none;
}

.nav__links {
  list-style: none;
}

.nav__links li {
  display: inline-block;
  padding: 0 20px;
}

.nav__links li a {
  transition: all 0.3s ease 0s;
}

.nav__links li a:hover {
  color: $hover-color;
}

@media screen and (max-width: 414px) {
  li, a {
    font-size: 10px;
  }

  header {
    padding: 10px 3% 10px 10vw;
  };
}
