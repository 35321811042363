@import "./src/styles/vars";
@import "./src/styles/mixins";


$lineHeight: $size_x1 / 4;
$animationSpeed: 200ms;

.volume  {
  display: flex;
  align-items: center;

  height: 32px;
}

.volumePanel {
  display: inline-block;

  width: 0;
  height: 100%;

  -moz-transition: margin .2s cubic-bezier(0.4,0.0,1,1),width .2s cubic-bezier(0.4,0.0,1,1);
  -webkit-transition: margin .2s cubic-bezier(0.4,0.0,1,1),width .2s cubic-bezier(0.4,0.0,1,1);

  transition: margin .2s cubic-bezier(0.4,0.0,1,1),width .2s cubic-bezier(0.4,0.0,1,1);

  @include usePointerCursor();
  outline: 0;
}

.volumeSlider {
    position: relative;

    width: 64px;

    margin-left: $size_x1 / 2;

    height: 100%;
    overflow: hidden;

  opacity: 0;
}

.volumeSliderHandle {
  position: absolute;
  top: 50%;

  width: 12px;
  height: 12px;

  border-radius: 6px;

  margin-top: -6px;

  background: #ffffff;

  &::before {
    content: '';

    position: absolute;
    display: block;

    top: 50%;
    left: -58px;

    height: 3px;
    width: 64px;

    margin-top: -2px;

    background-color: #ffffff;
  }

  &::after {
    content: '';

    position: absolute;
    display: block;

    top: 50%;
    left: 6px;

    height: 3px;
    width: 64px;

    margin-top: -2px;

    background-color: rgba(#ffffff, .3);
  }
}

.short {
  width: 0;
  height: 0;

  transition: width $animationSpeed * 2;
}

.full {
  width: 64px;
  height: 100%;

  transition: width $animationSpeed * 2;

  margin-right: $size_x1;
}

.showBar {
  opacity: 1;

  transition: opacity $animationSpeed * 5;
}
