$nav-bar-bg-color: #000000;
$white: #ffffff;

$main-bg-color: #1e1e1e; // 1d1d1d
$footer-bg-color: #323232;

$main-color: #0b191e;
$main-color-light: #dddddd;
$link-main-color: #0b191e;
$main-text-color: $white;

$hover-color: #4074b5;
$focus-color: #00cbff;
$notifications-color: #FF0000;

// sizes
$size_x1: 8px;
$size_x2: $size_x1 * 2; // 16
$size_x3: $size_x1 * 3; // 24
$size_x4: $size_x1 * 4; // 32
$size_x5: $size_x1 * 5; // 40
$size_x6: $size_x1 * 6; // 48
$size_x7: $size_x1 * 7; // 56
$size_x8: $size_x1 * 8; // 64

// borders
$border-radius_x1: $size_x1 / 2;
$border-radius_x2: $border-radius_x1 * 2;
$border-radius_x3: $border-radius_x1 * 3;

// fonts
$main-font-family: 'Montserrat', 'Helvetica Neue', 'Arial', sans-serif;
$main-font-size: 14px;
$main-font-size-big: 16px;
$main-font-size-medium: 12px;
$main-font-size-small: 10px;
$main-line-height: 18px;
$bigger-line-height: 22px;
$smaller-line-height: 16px;

//z-indexes
$standard-negative-z-index: -1;
$z-index: 1;
$z-index-navigation: 10;

$standardColorTheme: $main-text-color, $nav-bar-bg-color, $white;
$unfilledColorTheme: $main-text-color, $nav-bar-bg-color, $nav-bar-bg-color;
