@import "./src/styles/vars";
@import "./src/styles/mixins";


$stud-button-size: 12px;
$fill-color: #ff0000;
$time-info-width: 70px;

.timeLine {
  @include usePointerCursor();

  height: 4px;
  margin-top: -2px;

  display: flex;
  align-items: center;
}

.line {
  width: 100%;
  height: 2px;

  // overflow: hidden;

  background-color: $white;

  position: relative;
}

.line.__hover {
  height: 4px;

  transition: height .2s;
}

.fillLine {
  position: absolute;

  height: 100%;

  left: 0;
  top: 0;

  background-color: $fill-color;
}

.studButton {
  position: absolute;

  width: $stud-button-size;
  height: $stud-button-size;

  background-color: $fill-color;

  border: solid 1px transparent;
  border-radius: 50% 50%;

  top: -($stud-button-size / 2) + 1;

  animation: controls-showing .2s ease-in;
}

.timeInfo {
  padding: $size_x1 / 2;

  color: $white;

  width: $time-info-width;

  background-color: rgba(#000000, .3);
  border-radius: $border-radius_x1;

  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  top: -32px;

  @include no-select();
}

@keyframes controls-showing {
  0% {
    opacity: 0;
  }
  20% {
    opacity: 0.2;
  }
  40% {
    opacity: 0.4;
  }
  60% {
    opacity: 0.6;
  }
  80% {
    opacity: 0.8;
  }
  100% {
    opacity: 1;
  }
}
