@import "vars";

html, body {
  margin: 0;

  font-family: $main-font-family;
  font-size: $main-font-size;

  color: $main-color;
}

button {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;

  font-family: $main-font-family;
  font-size: $main-font-size;

  color: $link-main-color;
}

.navBar {
  position: relative;

  height: 56px;

  color: #ffffff;

  background-color: $nav-bar-bg-color;
}

.footer {
  background-color: $nav-bar-bg-color;
}
