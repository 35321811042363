@import "src/styles/vars";
@import "src/styles/mixins";


.videoContainer {
  position: relative;
  
  background-color: #000000;

  flex-grow: 1;
}

.videoContainer.__fullScreen {
  //height: 100vh;
  //
  //video {
  //  width: 100%;
  //}
}

.videoContainer.__float {
  //width: 480px;
  //height: 320px;

  position: fixed;
  right: $size_x2;
  bottom: $size_x2;

  video {
    width: 100%;
  }
}

.videoContainer.__hide {
  display: none;
}

.__stubFloat {
  width: 100%;
  height: 100% !important;
}

.videoControlButton {
  &:last-of-type {
    margin-right: $size_x1;
  }
}

.videoRestoreFromMiniControlButton {
  position: absolute;

  top: $size_x1;
  left: $size_x1;;
}

.videoCloseMiniControlButton {
  position: absolute;

  top: $size_x1;
}

.videoCloseMiniControlButton.__left {
  left: $size_x4 + $size_x1;
}

.videoCloseMiniControlButton.__right {
  right: $size_x1;
}

.videoAdvancedControls {
  background-color: #1e1726;
}

.liveIndicator {
  position: absolute;

  animation: controls-showing 2s ease-out;
}

.liveIndicator.__left {
  top: $size_x2;
  left: $size_x2;
}

.liveIndicator.__right {
  bottom: $size_x2;
  left: $size_x2;
}

.liveIndicator.__showWithControls {
  top: $size_x2 + $size_x4 + $size_x3;
}

.nowWatchingIndicator {
  position: absolute;

  top: $size_x2;
  left: $size_x2;

  animation: controls-showing 2s ease-out;
}

.nowWatchingIndicator.__showWithLive {
  left: $size_x2 + 56px;
}

.nowWatchingIndicator.__showWithControls {
  top: $size_x2 + $size_x4 + $size_x3;
}

.extControls {
}

.bigStateVideoAnimateIcon {
}

.videoBroadcastTimeLine {
  color: $white;
}

@keyframes controls-showing {
  0% {
    opacity: 0;
  }
  20% {
    opacity: .2;
  }
  40% {
    opacity: .4;
  }
  60% {
    opacity: .6;
  }
  80% {
    opacity: .8;
  }
  100% {
    opacity: 1;
  }
}
