@import "./src/styles/vars";
@import "./src/styles/mixins";

.videoControlButton {
  border: solid 1px transparent;
  background-color: transparent;

  padding: 0;

  height: $size_x4;
  width: $size_x4;

  display: flex;
  justify-content: center;
  align-items: center;

  @include usePointerCursor();
  @include useBorderBox();

  @include active() {
    outline: 0;
  }

  @include focus() {
    outline: 0;
    border: solid 1px $focus-color;
  }

  @include hover() {
    opacity: .9;
  }
}
