$scaleMaxFactor: .6;
$animationSpeed: .5s;

.bigStateVideoAnimateIcon {
  border-radius: 50%;

  fill: #ffffff;
  background-color: rgba(#666666, .18);

  transform: scale($scaleMaxFactor);
  opacity: 0;
  height: 0;

  animation: grow $animationSpeed, hiding $animationSpeed, hidingHeight $animationSpeed * 3;
}

@keyframes grow {
  from {
    transform: scale(0);
  }
  to {
    transform: scale($scaleMaxFactor);
  }
}

@keyframes hiding {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes hidingHeight {
  from {
    height: 300px;
  }
  to {
    height: 0;
  }
}
