.videoPlayerStub {
  display: flex;
  justify-content: center;
  align-items: center;
}

.videoPlayerStub.defaultBound {
  width: 100%;
  height: 680px;

  color: rgba(0, 255, 249, 0.45);
}
