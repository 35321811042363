@import url("https://use.typekit.net/ghx8mvc.css");

body {
  margin: 0;
  font-family: Montserrat, sans-serif;
  font-weight: 400;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: inherit;
  text-decoration: inherit;
}
