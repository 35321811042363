@import "./src/styles/vars";
@import "./src/styles/mixins";


.quality {
  position: relative;

  width: unset;

  padding-right: $size_x1;
  padding-left: $size_x1;
}

.qualityList {
  position: absolute;

  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  justify-content: center;

  padding-bottom: $size_x4;
}

.qualitySelectedView {
  margin-bottom: 3px;
  color: #ffffff;
}

.levelButton {
  border: solid 1px transparent;
  background-color: rgba(#000000, 0.2);

  padding: $size_x1 / 2;;

  display: flex;
  justify-content: center;
  align-items: center;

  color: #ffffff;

  @include useBorderBox();

  @include active() {
    outline: 0;
  }

  @include focus() {
    outline: 0;
    border: solid 1px $focus-color;
  }
}

.levelButton:not(:disabled) {
  @include hover() {
    @include usePointerCursor();
    text-decoration: underline;
  }
}

.levelButton:disabled {
  opacity: .4;
}

.levelButton.__currentLevel {
  font-weight: bold;
}
