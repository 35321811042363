.App {

}

.App-player-container {
  position: relative;
  width: 100%;
  height: 65vh;
  /*padding-bottom: 56.25%;*/
}

.App-player-custom-container {
  position: relative;
  width: auto;
  height:  max-content;
  background: black;
}

.App-player {
  border: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.App-ribbon {
  background-color: #1E1726;
  display: grid;
  grid-template-columns: auto auto 1fr 1fr;
  padding: 0 8vw;
}

.App-ribbon-logo {
  margin: -2vw 0 -3vw;
}

.App-ribbon-logo-img {
  height: 15vw;
}

.App-ribbon-ad {
  padding-top: 2.1vw;
}

.App-ribbon-title, .App-ribbon-chat-title, .App-ribbon-feedback-title {
  color: rgba(255, 255, 255, 1);
  font-size: 1.7vw;
  font-weight: 700;
  margin-bottom: 0.1vw;
}

.App-ribbon-chat-telegram, .App-ribbon-feedback-emoji {
  color: rgba(255, 255, 255, 1);
  font-size: 1.1vw;
  line-height: 1.2em;
  margin-bottom: 0.1vw;
  margin-left: 0.3vw;
}

.App-ribbon-subtitle {
  color: rgba(255, 255, 255, 1);
  font-size: 1.1vw;
  line-height: 1.2em;
  margin-bottom: 0.1vw;
}

.App-ribbon-ticket, .App-ribbon-chat-talk, .App-ribbon-feedback-send {
  color: rgba(255, 255, 255, 1);
  font-size: 1.7vw;
  font-weight: 700;
  margin-bottom: 0.5vw;
  text-decoration: underline;
}

.App-ribbon-chat, .App-ribbon-feedback {
  padding-top: 2.1vw;
  padding-left: 2vw;
}

.App-content {
  padding: 1vw 10vw 4vw;
}

.App-content-talk {
  padding-right: 10vw;
}

.App-content-talk-bits {
  display: grid;
  font-size: 1.25vw;
  padding-right: 5vw;
  margin-bottom: 3vw;
}

.App-content-talk > .App-content-talk-bits {
  grid-template-columns: auto 1fr auto auto;
}

.App-content-speaker-account {
  justify-content: center;
  align-items: center;
}

.App-content-icon, .App-ribbon-chat-logo {
  line-height: 1;
  margin-left: -0.25em;
  margin-right: 0.5em;
}

.App-content-icon-img {
  line-height: 1;
  margin-top: 0.2vw;
  margin-left: -0.4em;
  width: 3vw;
  height: 1.5vw;
  position: absolute;
}

.App-content-telegram-link {
  text-decoration: underline;
}

.App-content-talk-bits > * {
  padding: 0.25em 0.6em;
}

.App-content-talk-bits-date {
  margin-right: 2vw;
}

.App-content-talk-bits-time {
  margin-right: 2vw;
}

.App-content-talk-bits-tag {
  background-color: #F0F0F0;
  font-size: 0.7vw;
  line-height: 1.45em;
  letter-spacing: 0.05em;
  margin-right: 1vw;
  align-self: center;
}

.App-content-talk-bits-tag > .App-content-talk-bits-icon {
  margin-right: 0.25em;
}

.App-content-talk-bits-lang {
  background-color: #F0F0F0;
  font-size: 0.7vw;
  line-height: 1.45em;
  letter-spacing: 0.05em;
  align-self: center;
}

.App-content-speakers {
  padding-top: 3vw;
  padding-right: 10vw;
}

.App-content-talk-title, .App-content-speakers__title{
  font-size: 2vw;
  font-weight: 700;
  line-height: 1.2em;
  margin-bottom: 1vw;
}

.App-content-talk-tags {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.1vw;
  line-height: 1.2em;
  margin-bottom: 2vw;
}

.App-content-talk-subtitle, .App-content-show-notes-title {
  color: rgb(0, 0, 0, 0.7);
  font-size: 1.6vw;
  margin: 3vw 0 2vw;
}

.App-content-talk-description {
  font-size: 1.1vw;
  font-weight: 400;
  line-height: 1.6em;
}

.App-content-talk-description > p {
  margin: 0 0 1em 0;
}

.App-content-talk-show-notes {
  font-size: 1.1vw;
  font-weight: 400;
  line-height: 1.4em;
}

.App-content-show-notes-content * a {
  text-decoration-style: solid;
  text-decoration: underline;
}

.App-content-ask-speaker-content {
  margin-top:1.5em;
  border: 0;
  width: 90%;
  height: 560px;
}

.App-content-speaker {
  display: grid;
  grid-template-columns: auto 0.3fr 1fr;
  margin-top: 2vw;
  margin-bottom: 4vw;
  grid-gap: 3%;
}

.App-content-speaker-photo {
  width: 12vw;
  position: relative;
  height: 12vw;
}

.App-content-speaker-name {
  font-size: 1.7vw;
  font-weight: 700;
  margin-bottom: 0.5vw;
  margin-top: 1vw;
}

.App-content-speaker-affilation {
  color: rgba(0, 0, 0, 0.5);
  font-size: 1.1vw;
  line-height: 1.2em;
  margin-bottom: 0.5vw;
}

.App-content-speaker-description {
  color: rgba(0, 0, 0, 0.7);
  font-size: 1.1vw;
  line-height: 1.6em;
  margin-top: 1vw;
}

.App-content-speaker-account a {
  font-size: 1.1vw;
  font-weight: 700;
  margin-bottom: 1vw;
  margin-top: 0.2vw;
  margin-left: 2em;
  position: absolute;
}

.App-content-speaker-account span {
  font-size: 1.1vw;
  font-weight: 700;
  margin-bottom: 1vw;
}

.App-more {
  background-color: #F3F7FB;
  padding: 4vw 10vw 10vw;
}

.App-more-title {
  font-size: 2.25vw;
  font-weight: 700;
  margin-bottom: 2vw;
}

.App-more-events {
  display: flex;
  flex-wrap: wrap;
}

.App-more-event {
  background-color: #ffffff;
  clear: both;
  display: inline-block;
  margin-right: 1.5vw;
  margin-bottom: 1.5vw;
  width: 25vw;
  height: 20vw;
  border-radius: 20px;
}

.App-more-event:hover {
  box-shadow: 0 5px 15px rgba(0,0,0,0.3);
  transition: 0.4s ;
}

.App-more-event-date {
  background-color: #E0F0FF;
  font-size: 1.25vw;
  font-weight: bold;
  padding: 1.25vw 2.5vw;
  border-radius: 20px 20px 0px 0px;
}

.App-more-event-content {
  padding: 2.5vw;
}

.App-more-event-title {
  font-size: 1.4vw;
  font-weight: bold;
  line-height: 1.3em;
  margin-bottom: 0.5em;
}

.App-more-event-content > .App-content-talk-bits {
  grid-template-columns: auto auto 1fr;
}
