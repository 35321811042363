@import "src/styles/vars";
@import "src/styles/mixins";

.sponsorLogoContainer {
  height: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.sponsorLogoImage {
  width: auto;
  height: 100%;
  filter:contrast(50%);
}

.sponsorLogoImage:hover {
  filter: saturate(100%) grayscale(0%) contrast(100%) brightness(100%);
}

.monochrome {
  filter: opacity(50%);
}

.monochrome:hover {
  filter: opacity(100%);
}

.sponsorLogoGroupContainer {
  display: flex;
  justify-content: space-evenly;
  height: 80px;
  align-items: center;
  padding-top: 10px;
}
