@import "src/styles/vars";
@import "src/styles/mixins";


.liveIndicator {
  height: 22px;

  padding: 0 $size_x1;

  background: #898989;
  opacity: 0.8;

  border-radius: $border-radius_x1;

  display: flex;
  justify-content: center;
  align-items: center;

  @include no-select();
}

.liveIndicator.__active {
  background: #ff0000;
}

.livePoint {
  color: #ffffff;
  fill: #ffffff;

  svg {
    fill: #ffffff;
  }
}

.liveText {
  margin-left: $size_x1 / 2;
  color: #ffffff;
}

