@mixin active() {
  &:active {
    @content;
  }
}

@mixin focus() {
  &:focus {
    @content;
  }
}

@mixin hover() {
  &:hover {
    @content;
  }
}

@mixin usePointerCursor() {
  cursor: pointer;
}

@mixin useDefaultCursor() {
  cursor: default;
}

@mixin useBorderBox() {
  box-sizing: border-box;
}

@mixin no-select() {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

@mixin colorTheme($color, $backgroundColor, $fillColor) {
  color: $color;

  fill: $fillColor;
  background-color: $backgroundColor;
}

@mixin standardButtonsTheme {
  @include colorTheme($standardColorTheme...);
}

@mixin unfilledButtonsTheme {
  @include colorTheme($unfilledColorTheme...);
}

@mixin verticallyAlignedDisplayInlineBlock($alignment: middle) {
  display: inline-block;
  vertical-align: $alignment;
}

@mixin setObjectWidthHeight($widthSize, $heightSize) {
  width: $widthSize;
  height: $heightSize;
}
