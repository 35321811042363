@import "./src/styles/vars";
@import "./src/styles/mixins";

.root {
  position: relative;

  width: 100%;
  height: 100%;
}

.videoWrapper {
  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;
}

.root.__isFetching .videoWrapper {
  // special video hide
  clip: rect(1px, 1px, 1px, 1px);
}

.video {
  width: 100%;
  height: 100%;
}

.centerIndicator {
  position: absolute;

  top: 50%;
  left: 50%;

  transform: translateY(-50%) translateX(-50%);
}

.loader {
  position: absolute;
  display: none;

  top: 50%;
  left: 50%;

  transform: translateY(-50%) translateX(-50%);
}

.root.__isFetching .loader,
.root.__isLevelFetching .loader,
.root.__isVideoWaitingData .loader {
  display: block;
  animation: loadingOpacity 1s ease-in-out infinite;
}

@keyframes loadingOpacity {
  0% {
    opacity: .6;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: .6;
  }
}

@keyframes controls-showing {
  0% {
    opacity: .0;
  }
  50% {
    opacity: .5;
  }
  100% {
    opacity: 1;
  }
}

.controls {
  position: absolute;

  left: 0;
  bottom: 0;

  width: 100%;
  height: $size_x4;

  background-color: rgba(#000000, 0.2);

  color: #ffffff;
  fill: #ffffff;
  stroke: #ffffff;

  animation: controls-showing .5s ease-out;
}

.controlsButtons {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
}

.controlsLeftPanel {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.controlsRightPanel {
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.videoControlButton {
}

.controlsLeftPanel .videoControlButton {
  &:first-of-type {
    margin-left: $size_x1;
  }
}

.controlsRightPanel .videoControlButton {
  &:last-of-type {
    margin-right: $size_x1;
  }
}

.time {
  display: flex;
  align-items: center;
}

.liveBtn {
  margin-left: $size_x1 / 2;
  margin-bottom: 2px;

  background-color: transparent;
  border: solid 1px transparent;

  font-size: $main-font-size;
  font-weight: bold;

  padding: 0 $size_x1;

  display: flex;
  align-items: center;

  color: red;

  @include useBorderBox();

  @include active() {
    outline: 0;
  }

  @include focus() {
    outline: 0;
    border: solid 1px $focus-color;
  }

  @include hover() {
    @include usePointerCursor();
  }
}

.timeline {
}

.volume {
  margin-right: $size_x1;
}

.quality {
  //margin-right: $size_x1;
}
