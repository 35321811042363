@import "src/styles/vars";

.title {
  font-family: Montserrat, sans-serif;
  font-weight: 700;
  font-size: 60px;
  padding: 5vw 10vw 5vw;
}

.subtitle {
  font-family: Montserrat, sans-serif;
  padding: 0vw 10vw 7vw;
}

.button__container {
  display: flex;
  margin-right: 10vw;
  margin-left: 70%;
  margin-bottom: 5vw;
  align-items: end;
}
.button {
  display: inline-block;
  align-items: center;
  border-radius: 10px;
  background-color: $main-color;
  text-decoration: none;
  font-size: 10px;
  color: white;
}

@media screen and (max-width: 414px) {
  .title {
    font-size: 20px;
  }

  .subtitle {
    width: 70%;
    font-size: 10px;
  }
}

@media screen and (min-width: 601px) {
  .title {
    font-size: 60px;
  }

  .subtitle {
    max-width: 600px;
    font-size: 20px;
  }
}

